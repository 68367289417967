.article-header-image{
    margin-bottom: -100px;
    width: 80%;
}

.article-header-image:after{
    content: url("../../assets/img/article-image-after@2x.png");
    /*position: absolute;*/
}
.mt-6{
    margin-top: 6rem;
}
@media all and (min-width: 1200px){
    .mt-lg-10{
        margin-top: 10rem;
    }
}