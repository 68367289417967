/*.subscribe-control{*/
/*    background: transparent;*/
/*    border: none;*/
/*    border-bottom: 1px solid #d8d8d8;*/
/*    margin-right: 1.5rem;*/
/*}*/

.subscribe-control{
    background: transparent;
    border: none;
    padding: 1.5rem;
    border-bottom: 1px solid #d8d8d8;
    margin-right: 1.5rem;
    border-radius: 0;
}
.input-group>.input-group-append>.btn,
.input-group>.input-group-append>.input-group-text,
.input-group>.input-group-prepend:first-child>.btn:not(:first-child),
.input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child),
.input-group>.input-group-prepend:not(:first-child)>.btn,
.input-group>.input-group-prepend:not(:first-child)>.input-group-text {
    border-radius: .3rem;
}

.form-control:focus {
    color: #495057;
    background-color: transparent;
    border-bottom:2px solid #64B900;
    outline: 0;
    box-shadow:none;
}

.mw-250{
    min-width: 200px;
}



@media all and (max-width: 480px){

    .subscribe-control{
        margin-right: 0;
    }
    .input-group>.form-control {
        flex: 1 1 100%;
        text-align: center;
    }
    .subscribe-form .input-group{
        justify-content: center;
    }
    .input-group-append, .input-group-prepend {
        display: flex;
        align-items: center;
    }
}