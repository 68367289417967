.contact-input .ant-input {
    border-color: transparent;
    border-bottom: 1px solid var(--patek-green);
    padding: 1rem;
    border-radius: 0px;
    font-size: 1.1rem;
    transition: all .3s ease-in-out;
}
.contact-input .ant-input:focus{
    outline: none;
    box-shadow: none;
    border-bottom: 2px solid var(--patek-green);
}

.contact-input .ant-form-item-label > label {
    color: #313131;
    font-size: 1rem;
}

.contact-input .ant-form-item-label > label {
    color: #313131;
    font-size: 1rem;
}

button.contact-input {
    background-color: var(--patek-green);
    color: rgba(255, 255, 255, 0.8);
    text-transform: uppercase;
    height: 64px;
    border-color: transparent;
    transition: all .5s ease-in-out;
    box-shadow:none;
}

button.contact-input:hover, button.contact-input:focus {
    background-color: var(--patek-green-light);
    color: white;
    border-color: transparent;
    box-shadow: 0px 10px 20px rgba(100,185,0,0.3);
}
.right-9{
    right: 9%;
}
.right-5{
    right: 3rem;
}

@media all and (min-width: 1200px) {
    .mt-lg-15{
        margin-top: 15rem;
    }
    .position-lg-absolute{
        position: absolute;
    }
}
