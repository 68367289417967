:root {
    --nav-green: #64B900;
    --deep-footer-green: #16312C;
}
@-webkit-keyframes slideInDown {
    from {
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
        visibility: visible;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes slideInDown {
    from {
        -webkit-transform: translate3d(0, -100%, 0);
        transform: translate3d(0, -100%, 0);
        visibility: visible;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.slideInDown {
    -webkit-animation-name: slideInDown;
    animation-name: slideInDown;
}

.dropdown-menu {
    background-color: rgba(4, 35, 43, 0.95);
    color: white;
    z-index: 5000000000000;
    position: absolute;
    top: 90%;
}

.navbar {
    /*transition: all .5s ease-in-out; */
    box-sizing: border-box;
}



.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show > .nav-link {
    color: #e2fef0;
}

.navigation.inverse .navbar-nav .dropdown-toggle::after {
    filter: invert(1);
}

.navigation.inverse .navbar-nav .nav-link {
    color: #141715;
}

.navbar-light .navbar-nav .nav-link.active {
    border-bottom: 2px solid var(--nav-green, #64B900);
}

.navigation .navbar-nav .nav-link {
    font-size: 13px;
    display: flex;
}

.dropdown-toggle::after {
    display: inline-flex;
    margin-left: .255em;
    vertical-align: .255em;
    content: url("../../../assets/img/drop-down-icon.svg");
    border: none;
}

.dropdown-item:focus, .dropdown-item:hover {
    color: var(--nav-green, #64B900);
    text-decoration: none;
    background-color: transparent;
}

.dropdown-item {
    display: block;
    width: 100%;
    padding: .6rem 1.5rem;
    clear: both;
    font-size: .8rem;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
}

.navbar-toggler:focus {
    text-decoration: none;
    outline: 1px solid var(--nav-green, #64B900);
}

@media all and (max-width:992px){
    .navbar-light .navbar-toggler-icon {
        filter: invert(1) brightness(1)!important;
    }
}
@media all and (max-width: 480px) {
    .w-25{
        width: 17%!important;
    }
    .navbar {
        padding: 0rem;
    }

    .footer-logo img{
        width: 25%;
    }

    .navbar-light .navbar-nav .nav-link {
        position: relative;
        border-bottom: none;
    }

    .navigation .navbar-nav .nav-link {
        font-size: 0.9rem;
        margin-left: 0;
    }

    .navbar-light .navbar-nav .nav-link.active {
        border-bottom: none;
        margin-left: 0;
    }
    .navbar-light .navbar-nav .nav-link.active:after {
        content: '';
        position: absolute;
        bottom: 3px;
        width: 10%;
        background: var(--nav-green, #64B900);
        height: 3px;
    }
    .navbar-nav {
        padding-top: 2rem;
    }



    .navbar-light .navbar-toggler {
        color: rgb(255, 255, 255);
        border-color: rgb(255, 255, 255);
    }
    .navbar-toggler:focus{
        outline: none;
    }


}

@media all and (min-width: 1200px) {
    .dropdown-menu {
        left: 0;
        opacity: 0;
        visibility: hidden;
        transition: all .3s ease-in-out;
    }
    .w-70{
        width: 70%;
    }
    .navbar{
        position: absolute;
        width: 100%;
        z-index: 3000;
    }
    .navbar-light .navbar-nav .nav-link{
        padding: 1rem 0rem;
        margin:  0rem 1rem;
    }
    .navbar-nav .dropdown:hover .dropdown-menu{
        /*display: block;*/
        visibility: visible;
        opacity: 1;
    }
    .navbar.sticky-bg {
        background-color: var(--deep-footer-green,#16312C);
        animation: slideInDown 420ms cubic-bezier(0.165, 0.84, 0.44, 1);
        position: fixed;
        top:0;
        width: 100%;
        z-index: 10000;
        padding: 0rem 8rem !important;
        left: 0;
        right: 0;
        height: auto;
    }

    .navbar.sticky-bg .nav-brand img {
        margin-top: -15px;
    }

}