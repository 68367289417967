@font-face {
    font-family: 'Quenales Bold';
    src: url("../fonts/Qanelas/Qanelas-Bold.otf");
    font-weight: bold;
}

@font-face {
    font-family: 'Quenales Regular';
    src: url("../fonts/Qanelas/Qanelas-Regular.otf");
    font-weight: normal;
}

@font-face {
    font-family: 'Hk Grotesk Bold';
    src: url("../fonts/hk-grotesk/HKGrotesk-Bold.otf");
    font-weight: bold;
}

@font-face {
    font-family: 'Hk Grotesk Regular';
    src: url("../fonts/hk-grotesk/HKGrotesk-Regular.otf");
    font-weight: normal;
}


:root {
    --hk-grotesk-regular: "Hk Grotesk Regular", Montserrat, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    --hk-grotesk-bold: "Hk Grotesk Bold", Montserrat, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    --patek-green: #64B900;
    --light-black: #313131;
    --patek-deep-green: #2A442E;
    --patek-green-light: rgba(100, 180, 0, 0.7);
    --patek-green-light-2: #F7FDF0;
    --quenales-bold: "Quenales Bold", Montserrat, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    --quenales-regular: "Quenales Regular", Montserrat, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    --gray-gradient-30: linear-gradient(100deg, rgba(230, 230, 232, 0.3) 0%, rgba(230, 230, 232, 0.3) 62%, rgba(230, 230, 232, 0.3) 100%);
}


body {
    font-family: var(--hk-grotesk-regular);
    margin: 0;
    font-size: 1rem;
    line-height: 1.6rem;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* bg colors */
.bg-patek-green {
    background-color: var(--patek-green);
}

.bg-black {
    background-color: black;
}

.bg-faded-black {
    background-color: rgba(0, 0, 0, 0.3);
}

.bg-patek-light-green {
    background-color: var(--patek-green-light-2);
}

.bg-gray-gradient-30 {
    background: var(--gray-gradient-30);
}
.green-skeleton .ant-skeleton-content .ant-skeleton-paragraph > li{
    /*color:  var(--patek-green);*/
    /*background-color:  var(--patek-green);*/
}
.patek-green {
    color: var(--patek-green);
}

.patek-deep-green {
    color: var(--patek-deep-green);
}

.patek-deep-green:hover {
    color: var(--patek-green);
}

.light-black {
    color: #313131;
}

.ls-1 {
    letter-spacing: 1rem;
}

.ls-0-5 {
    letter-spacing: .5rem;
}
.ls-0-3 {
    letter-spacing: .3rem;
}


/*fonts*/

.hk-grotesk-regular {
    font-family: var(--hk-grotesk-regular);
}

.hk-grotesk-bold {
    font-family: var(--hk-grotesk-bold);
}

.quenales-bold {
    font-family: var(--quenales-bold);
}

.fs-4-5 {
    font-size: 4.5rem;
}
.fs-4 {
    font-size: 4rem;
}
.fs-3-5 {
    font-size: 3.5rem;
}

.fs-2-5 {
    font-size: 2.5rem;
}
.fs-2 {
    font-size: 2rem !Important;
}

.fs-1 {
    font-size: .8rem;
}

.fs-1-2 {
    font-size: 1.2rem;
}

.fs-1-5 {
    font-size: 1.5rem;
}

.fs-0-7 {
    font-size: .7rem;
}


/*positioning*/
.bottom-right {
    bottom: 0%;
    right: 5%;
}

.right-center {
    right: 15%;
    top: 15%;
}

.center-center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.left-left {
    top: 50%;
    left: 35%;
    transform: translate(-50%, -50%);
}

.left-center {
    left: 15%;
    top: 15%;
}

.bottom-left-0 {
    bottom: 0%;
    left: 0%;
}

.bottom-center {
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}

.bottom-left-5 {
    bottom: 5%;
    left: 5%;
}

.-bottom-10 {
    bottom: -10%;
}

.-right-30 {
    right: -40%;
}

.center-right {
    right: -14%;
    top: 50%;
    transform: translateY(-50%);
}

.center-left {
    left: -20%;
    top: 50%;
    transform: translateY(-50%);
}

.center-bottom {
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
}
.b-20 {
    bottom: 20px;
}
.b-10 {
    bottom: 10px;
}

.left-bottom-10-40 {
    left: 80px;
    bottom: 40%;
}
.left-bottom-2-10 {
    left: 2%;
    bottom: 10%;
}
.right--250{
    right: -250px;
}

.z-index-1 {
    z-index: 1;
}

.z-index--1 {
    z-index: -1;
}


/*utitlities*/
.overflow-x-hidden {
    overflow-x: hidden;
}

.overflow-y-hidden {
    overflow-y: hidden;
}

.w-35 {
    width: 35% !important;
}
.w-32 {
    width: 32% !important;
}
.w-45{
    width: 45% !important;
}


.w-30 {
    width: 30% !important;
}
.w-20 {
    width: 20% !important;
}
.w-25 {
    width: 25% !important;
}
.w-15 {
    width: 15% !important;
}
.w-22 {
    width: 22% !important;
}
.w-70 {
    width: 70% !important;
}
.w-80 {
    width: 80% !important;
}

.h-25-px {
    heigth: 50px;
}

.flex-basis-30 {
    flex-basis: 30%;
}

.flex-basis-60 {
    flex-basis: 60%;
}

.flex-basis-33 {
    flex-basis: 33.3%;
}

.min-height-250 {
    min-height: 250px;
}
.min-height-200 {
    min-height: 200px;
}
.min-height-auto{
    min-height: auto;
}
.height-100 {
    height: 100%;
}

.mh-vh-100 {
    min-height: 100vh;
}

.mh-vh-130 {
    min-height: 130vh;
}

.mh-vh-50 {
    min-height: 50vh;
}

.mh-vh-60 {
    min-height: 60vh;
}

.mh-vh-70 {
    min-height: 70vh;
}

.height-40 {
    height: 40%;
}

/*filter*/
.brigthness-0-7 {
    filter: brightness(0.7);
}

.filter-brightness-0-5 {
    filter: brightness(.5);
}

.filter-brightness-0-3 {
    filter: brightness(.3);
}



/*buttons*/
.btn-patek-green {
    background-color: var(--patek-green);
    color: white;
    padding: 1rem 2rem;
    border-radius: .3rem;
    transition: all .3s ease-in-out;
    box-shadow: 0px 5px 10px rgba(22, 254, 13, 0.05);
}

.btn-patek-green:hover {
    background-color: var(--patek-green-light);
    color: white;
}


/*links*/

.gray-link{
    color: #bfbfbf;
}
.gray-link:hover{
    color: #bfbfbf05;
}
.footer-links {
    color: white;
    margin-bottom: 1rem;
    text-decoration: none;
    transition: all .3s ease-in-out;
}

.footer-links:hover {
    color: #64B900;
    text-decoration: none;
}
.patek-link{
    color: var(--patek-green);
    font-weight: bold;
    font-size: .8rem;
    transition: all .3s ease-in-out;
    text-transform: capitalize;
    text-decoration: underline;
}
.patek-link:hover{
    color: var(--patek-green-light);
    opacity: 0.7;
}
.footer-content li {
    margin-bottom: 1rem;
}

.navigation .navbar-nav .nav-link {
    color: white;
    cursor: pointer;
    margin: 0rem .5rem;
    transition: all .3s ease-in-out;
    border-bottom: .2rem solid transparent;
}

.navigation .navbar-nav .nav-link:hover,.navigation .navbar-nav .nav-link:focus{
    color: var(--patek-green);
}

.navigation .navbar-nav .nav-link.active {
    color: white;
    border-bottom: .2rem solid var(--patek-green);
    margin: 0rem .5rem;
}


.form-control .ant-form-item-control {
    line-height: 0px;
}

/* borders */
.border-top-green-1 {
    border-top: .3rem solid var(--patek-green);
}

.cursor-pointer{
    cursor: pointer;
}

/* foreground colors */
.text-black {
    color: #313131;
}

.text-green-1 {
    color: #2A442E;
}
.text-patek-green {
    color: #24B900;
}
.text-white-80{
    color: rgba(255,255,255,0.8);
}
.text-gray{
    color: #d8d8d8;
    transition: all .3s ease-in-out;
}
.text-gray:hover{
    color: #d8d8d8;
    opacity: .7;
}
.text-gray-2{
    color: rgba(42,68,42,0.5);
}


/* border radius */
.br-2 {
    border-radius: 2rem;
}
.border-top-line{
    border-top: 1px solid var(--patek-green);
    position: relative;
}

.border-top-line:before{
    content: '';
    display: inline-block;
    width: 15%;
    background-color: var(--patek-green);
    height: 5px;
    left:0;
    top: -3px;
    position: absolute;
}

.border-top-line-only{
    border-top: 1px solid var(--patek-green);
    position: relative;
}

.br-5 {
    border-radius: 5rem;
}

/* box-shadows */
.bs-1 {
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.02);
}
.bs-2 {
    box-shadow: 0px 0px 100px rgba(0, 0, 0, 0.09);
}

/*margins*/
.mb-10 {
    margin-bottom: 7rem;
}
.mb--3 {
    margin-bottom: 3rem;
}

/*heights*/
.h-150{
    height: 150px;
}
.h-250{
    height: 250px;
}

/*paddings*/
.pt-10{
    padding-top: 10rem;
}

.bg-cover{
    background-size: cover;
}
.bg-no-repeat {
    background-repeat: no-repeat;
}
.meet-me-bg{
    background:linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0,0,0 , 0.9)), url("../img/Meet-me-bg@2x.png");
    background-size: cover;
}
@media all and (min-width: 1200px){

    .w-lg-35{
        width: 35%;
    }
    .w-lg-40{
        width: 40%;
    }
    .mh-lg-250{
        min-height: 250px;
    }

    .mh-lg-90{
        min-height: 90px;
    }
    .h-lg-100vh{
        height: 100vh;
    }
    .mh-lg-70vh{
        min-height: 70vh;
    }
    .mh-lg-60vh{
        min-height: 60vh;
    }
    .mh-lg-100vh{
        min-height: 100vh;
    }

    .min-height-auto{
        min-height: auto;
    }
    .fs-lg-4{
        font-size: 4rem;
    }
    .fs-lg-4-5{
        font-size: 4.5rem;
    }
    .ls-lg-1{
        letter-spacing: 1rem;
    }
    .ls-lg-0-5{
        letter-spacing: .5rem;
    }

    .fs-lg-2-5{
        font-size: 2.5rem;
    }
    .mb-lg-10{
        margin-bottom: 10rem;
    }
    .br-lg-5{
        border-radius: 5rem;
    }
    .fs-lg-2{
        font-size: 2rem !important;
    }
    .w-lg-15{
        width: 15%!important;
    }
    .w-lg-20{
        width: 20%!important;
    }

    /* grid display */
    .d-grid {
        display: grid;
    }

    .grid-template-3-3 {
        grid-template-rows: repeat(3, 1fr);
        grid-template-columns: repeat(3, 1fr);
    }

    .grid-row-gap-1 {
        grid-column-gap: 1rem;
    }

    .grid-column-gap-1 {
        grid-row-gap: 1rem;
    }

    .grid-row-span-1-3 {
        grid-row-start: 1;
        grid-row-end: 3;
    }

    .grid-column-span-2-4 {
        grid-column-start: 2;
        grid-column-end: 4;
    }

    .calc-height-100-10 {
        height: calc(100vh - 10px);
    }

    .flex-basis-lg-30{
        flex-basis: 30%;
    }
    .w-lg-75{
        width: 75%;
    }
    .meet-me-bg{
        background: none;
    }

    .meet-me-bg h1{
        font-size: 2rem;
    }
}

@media all and (max-width: 480px){

    h3 {
        font-size: 1.2rem;
    }
    .grid-template-3-3 {
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 10px;
    }
    .grid-template-3-3 > div {
        height: 300px;
        margin-bottom: 2rem;
    }
    .min-height-200{
        min-height: 170px;
    }
    .w-25-sm {
        width: 25% !important;
    }

    p.bg-patek-green {
        font-size: .85rem;
    }

    .paginate-btn {
        width: 120px;
        font-size: 0.8rem;
        padding: 4px 4px;
    }

    .fs-2 {
        font-size: 1.7rem !Important;
    }


}