
.job--card{
    background-color: white;
    padding: 2rem;
    box-shadow: 0px 5px 10px rgba(0,0,0,0.1);
    transform: translateY(0);
    transition: all .3s ease-in-out;
    margin-bottom: 3rem;
}
.job--card-description{
    white-space: pre-wrap;
}
.job--card-title{
    color: #2A442E;
    font-weight: bolder;
}
.job--card:hover{
    box-shadow: 0px 5px 30px rgba(0,0,0,0.05);
    transform: translateY(-10px);
}

.job--link{
    color: #24B900;
    cursor: pointer;
    font-weight: bold;
    transition: all .3s ease-in-out ;
}

.job--link:hover{
    color: #2A442E;
}

.career--modal .modal-content{
    margin-top: 5rem;
}

@media all and (min-width:1200px){
    .career--article-header{
        margin-top: 1.5rem !important;
    }
    .career--section{
        /*height: 1100px;*/
    }
}
@media all and (max-width:480px){
    .career--article-header{
        margin: 1.5rem 0 !important;
    }
}
