/*.logo-placeholder{*/
/*    bottom: -10rem;*/
/*    right: 3rem;*/
/*    width: 30%;*/
/*    z-index: 1000;*/
/*    height: 300px;*/
/*    box-shadow: 0px 10px 30px rgba(0,0,0,0.05);*/
/*}*/
.header-card.logo-placeholder {
    bottom: -18rem;
}
.placeholder-label{
    bottom: 10px;
    left: 10px;
}
.text-underline{
    text-decoration:underline;
}
.w-500px{
    width: 500px;
}


@media all and (max-width: 480px){
    .logo-placeholder {
        bottom: -5rem;
        right: 2rem;
        width: 80%;
        z-index: 1000;
        height: 300px;
        box-shadow: 0px 10px 30px rgba(0,0,0,0.05);
    }
    .header-card.logo-placeholder {
        bottom: -10rem;
        /*right: 2rem;*/
        /*width: 80%;*/
        /*z-index: 1000;*/
        /*height: 300px;*/
        /*box-shadow: 0px 10px 30px rgba(0,0,0,0.05);*/
    }
    .header-card.logo-placeholder span {
        font-size: 12px;
    }
}

@media all and (min-width:992px){

    .logo-placeholder{
        bottom: -4rem;
        right: 3rem;
        width: 30%;
        z-index: 1000;
        height: 300px;
        box-shadow: 0px 10px 30px rgba(0,0,0,0.05);
    }

}

@media all and (min-width:1200px){

    .logo-placeholder{
        bottom: -6rem;
        right: 3rem;
        width: 30%;
        z-index: 1000;
        height: 300px;
        box-shadow: 0px 10px 30px rgba(0,0,0,0.05);
    }

}