.slide-carousel .carousel-control-next,.slide-carousel .carousel-control-prev{
    width: 50px;
    height: 50px;
    background: #64B900;
    color: white;
    opacity: 1;
    top: auto;
}

.slide-carousel .carousel-control-prev {
    left: 51%;
    bottom: 20%;
}

.slide-carousel .carousel-control-next {
    left: 60%;
    bottom: 20%;
}

.slide-carousel .carousel-control-prev-icon{
    background-image: url("../../../assets/img/carousel-previous-icon.svg");
}
.slide-carousel .carousel-control-next-icon{
    background-image: url("../../../assets/img/carousel-next-icon.svg");
}

.slide-header{
    margin-top: 2rem!important;
}

.slide-p{
    min-height: 280px;
}

@media screen  and (max-width: 480px){

    .slide-carousel .carousel-control-prev {
        left: 0%;
        bottom: -12%;
    }
    .slide-carousel .carousel-control-next {
        left: 20%;
        bottom: -12%;
    }



}