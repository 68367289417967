
.dotted-arch-position-1,.dotted-arch-position-2{
    top: 30% ;
    left:75%;
    width: 10%;
    transform: rotate(90deg);
    opacity: .7;
    filter: hue-rotate(-25deg);
}
.dotted-arch-position-2{
    top: 60% ;
}

@media all and (min-width:992px){

    .section-text h3{
        font-size: 1.4rem;
    }
    .dotted-arch-position-1,.dotted-arch-position-2{
        top: 45%;
        left: 29%;
        width: 4%;
        transform: rotate(0deg);
        opacity: .7;
        filter: hue-rotate(-25deg);
    }
    .dotted-arch-position-2{
        left: 61%;
    }
}

@media all and (min-width:1200px){
    .section-text h3{
        font-size: 1.7rem;
        padding-right: 2rem;
    }
    .dotted-arch-position-1,.dotted-arch-position-2{
        top: 42% ;
        left: 31%;
        width: 4%;
        transform: rotate(0deg);
        opacity: .7;
        filter: hue-rotate(-25deg);
    }
    .dotted-arch-position-2{
        left: 59%;
    }

}

