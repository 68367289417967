.with-square{

}
.with-square:after{
    content: '';
    display: inline-block;
    width: 10px;
    height: 10px;
    background-color: #64B900;
}

.header__text a{

}