.secondary-nav{
    border-bottom: 1px solid rgba(0,0,0,0.03);
    padding: 1rem 2rem;
    background-color: transparent !important;
}

.secondary-nav__link{
    transition: all .5s ease-in-out;
    margin-left: 2rem;
    letter-spacing: .2rem;
    font-size: .8rem;
    padding: 1rem;
    color: var(--light-black);
}
.secondary-nav__link:hover{
    color: var(--patek-green);
    margin-left: 2rem;
}
.secondary-nav__link:focus,.secondary-nav__link:active,.secondary-nav__link.active{
    color: var(--light-black);
    border-bottom: 5px solid var(--patek-green);
    font-weight: bold;
}


@media all and (max-width: 480px){
    .secondary-nav {
        border-bottom: 1px solid rgba(0,0,0,0.03);
        padding: 1rem ;
    }
    .secondary-nav li {
        display: block;
    }
    .secondary-nav li {
        height: 50px;
    }
    .secondary-nav__link {
        display: inline-block;
        padding-left: 0;
        padding-bottom: .5rem;
        margin-left: 0;
    }
    .secondary-nav__link:hover{
        margin-left: 0;
    }
}